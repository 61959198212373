/** @jsx jsx */
import { jsx, Box, Container } from 'theme-ui'
import React, { useEffect } from 'react'
import SubpageLayout from 'components/SubpageLayout'
import { ApplicationState } from 'store'
import { connect, useDispatch } from 'react-redux'
import { definePreviousPage, defineNextPage, Page } from 'store/pages'
import { navigate } from 'gatsby'
import ScheduleConsultForm from './ScheduleConsultForm'

interface Props {
  children?: React.ReactNode
  className?: string
  globalPages?: Page[]
}

const ScheduleDemo: React.FC<Props> = ({
  children,
  className,
  globalPages,
}): React.ReactElement => {
  const globalDispatch = useDispatch()

  useEffect(() => {
    const previousPage = globalPages[3]
    const previousGotoFunction = () => {
      return navigate(previousPage.path)
    }
    globalDispatch(defineNextPage(null))
    globalDispatch(
      definePreviousPage({
        ...previousPage,
        gotoFunction: previousGotoFunction,
      })
    )
  })

  return (
    <SubpageLayout>
      <Container className="container" sx={{ maxWidth: '80rem' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            width: '100%',
            height: '100%',
          }}
        >
          <ScheduleConsultForm />
        </Box>
      </Container>
    </SubpageLayout>
  )
}

const mapStateToProps = ({ pages }: ApplicationState) => ({
  globalPages: pages.pages,
  // exploreIndex: pages.exploreIndex,
  // profileIndex: pages.profileIndex,
})

export default connect(mapStateToProps)(ScheduleDemo)
