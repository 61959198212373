/** @jsx jsx */
import { jsx } from 'theme-ui'
import React from 'react'
import ScheduleDemo from 'components/ScheduleDemo'
import SubpageBg from 'components/ui/SubpageBg'

const DemoPage = () => {
  return (
    <React.Fragment>
      <SubpageBg>
        <ScheduleDemo />
      </SubpageBg>
    </React.Fragment>
  )
}

export default DemoPage
