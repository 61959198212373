/** @jsx jsx */
import { jsx } from 'theme-ui'
import React from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import GalleryArrowBtn from 'components/GalleryArrowBtn'
import { MediaPack } from 'content/profile/types'
interface Props {
  children?: React.ReactNode
  className?: string
  pageIndex?: number
  slides: React.FC<MediaPack>[]
  nextSlide: Function
  previousSlide: Function
}

const CaseStudyDetails: React.FC<Props> = ({
  children,
  className,
  pageIndex,
  slides,
  nextSlide,
  previousSlide,
}): React.ReactElement => {
  return (
    <motion.div className={className}>
      {children}
      <AnimatePresence>
        {slides.length > 1 && (
          <motion.div
            sx={{
              display: 'flex',
              width: '100%',
              height: '100%',
              position: 'relative',
              flex: 0,
              justifyContent: 'center',
            }}
          >
            <GalleryArrowBtn pointLeft onClick={() => previousSlide()} />

            <GalleryArrowBtn onClick={() => nextSlide()} />
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  )
}

export default CaseStudyDetails
