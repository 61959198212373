/** @jsx jsx */
import { jsx } from 'theme-ui'
import React from 'react'
import { motion } from 'framer-motion'
import SubpageModal from 'components/SubpageModal'
import { Context } from 'components/Layout'
interface Props {
  children?: React.ReactNode
  className?: string
}

const SubpageLayout: React.FC<Props> = ({
  children,
  className,
}): React.ReactElement => {
  return (
    <Context.Consumer>
      {context => (
        <motion.div
          sx={{
            // borderWidth: 1,
            // borderStyle: 'solid',
            // borderColor: '#000',
            boxSizing: 'border-box',
            margin: 0,
            minWidth: 0,
            display: 'flex',
            height: '100%',
            flexDirection: 'column',
            alignItems: 'center',
            // p: context.isLandscape ? '2% 0 2% 0' : '5%',
            p: '1rem',
            overflow: 'hidden',
          }}
          className={className}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
        >
          {children}
          <SubpageModal />
        </motion.div>
      )}
    </Context.Consumer>
  )
}

export default SubpageLayout
