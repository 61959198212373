/** @jsx jsx */
import { jsx, Flex, Label, Input, Select, Radio, Button } from 'theme-ui'
import React, { useContext, useState } from 'react'
import { Formik, Field, Form, useField } from 'formik'
import { motion, AnimatePresence } from 'framer-motion'
import { Context } from 'components/Layout'
import FormPage1 from '../FormPage1'
import FormPage2 from '../FormPage2'
import { Context as APIContext } from 'components/APIProvider'
import fadeAnimations from 'animations/fade'
import { validationSchema as validationSchemaPage1 } from '../FormPage1/FormPage1'
import { validationSchema as validationSchemaPage2 } from '../FormPage2/FormPage2'
import * as Yup from 'yup'
import CloseBtn from 'components/CloseBtn'

interface Props {
  children?: React.ReactNode
  className?: string
  isSubForm?: boolean
}

export interface FormProps {
  sendEmail: boolean
  attendeeID?: string
  demoName: string
  firstName?: string
  lastName?: string
  email?: string
  company?: string
  preferredDay?: string
  secondaryDay?: string
  country?: string
  researchArea?: string
  readoutPlatform?: string
  readoutPlatformTextfield?: string
}

export const exampleRequest: FormProps = {
  sendEmail: true,
  // attendeeID: 17,
  demoName: 'NANOSTRING_SOFTWARE_DEMO',
  // email: 'jallen@yipkos.com',
  // firstName: 'James',
  // lastName: 'Allen',
  // company: 'Yipkos',
  // preferredDay: '25',
  // secondaryDay: '26',
  // country: 'United States',
  // researchArea: 'Immunology',
  // readoutPlatform: 'nCounter',
}

export const initialValues: FormProps = {
  sendEmail: true,
  attendeeID: '',
  demoName: 'NANOSTRING_DAS_CONSULT',
  email: '',
  firstName: '',
  lastName: '',
  company: '',
  preferredDay: '',
  secondaryDay: '',
  country: '',
  researchArea: '',
  readoutPlatform: '',
  readoutPlatformTextfield: '',
}

const RadioInput: React.FC = ({ label, ...props }) => {
  const [field, meta] = useField(props)
}

// const apiRoot = 'https://api.nanostring.yipkos.com/'
// const getToken = `${apiRoot}oauth/token`
// const scheduleDemo = `${apiRoot}api/attendee/schedule-demo`

// const clientId = '7a36d777-6c50-4f00-982d-94a9a6bbf717'
// const clientSecret = '227d0e79-baec-4e3c-b7af-6635aa55a8b6'
// const grantType = 'password'
// const tempToken =
//   'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJzb21lX2lkIiwibmJmIjoxNTg5NTE1MzY0LCJleHAiOjE1OTA4MTEzNjQsImlzcyI6Imh0dHA6Ly9sb2NhbGhvc3Q6NTYyNzUiLCJhdWQiOiJodHRwOi8vbG9jYWxob3N0OjU2Mjc1In0.9lco6fRyyd7f-alrIqHqiLL7w8dQRkoI4A-mfFqVQD4'

export const WizardStep = ({ children }) => children

// const validationSchema = Yup.object({
//   ...validationSchemaPage1,
//   ...validationSchemaPage2,
// })

const validationSchema = validationSchemaPage1.concat(validationSchemaPage2)

const ScheduleConsultForm: React.FC<Props> = ({
  children,
  className,
  isSubForm,
}): React.ReactElement => {
  const [formSubmitted, setFormSubmitted] = useState(null)
  const [page2IsOpen, setPage2IsOpen] = useState(false)
  const { isLandscape } = useContext(Context)
  const { submitDemoForm, state } = useContext(APIContext)
  const populatedValues: FormProps = {
    ...state,
    sendEmail: true,
    demoName: 'NANOSTRING_SOFTWARE_DEMO',
  }
  for (const key in initialValues) {
    initialValues[key] = populatedValues[key] || ''
  }

  return (
    <motion.div
      className={className}
      sx={{
        position: 'relative',
        label: {
          display: 'flex',
          alignItems: 'center',
          textAlign: 'left',
        },
        height: '100%',
      }}
    >
      <AnimatePresence>
        <motion.div
          {...fadeAnimations}
          sx={{
            textAlign: 'left',
            height: '100%',
            justifyContent: 'center',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <h3 sx={{ color: '#333333', textAlign: 'center' }}>
            Schedule a <span className="highlighted">Live</span> Project
            Consultation
          </h3>
          <Formik
            initialValues={initialValues}
            onSubmit={async (data, { setSubmitting, resetForm }) => {
              setSubmitting(true)
              await submitDemoForm(data)
              setSubmitting(false)

              setPage2IsOpen(false)
              setFormSubmitted(true)
              resetForm()
            }}
            validateOnBlur={false}
            validateOnMount={false}
            validateOnChange={true}
            validationSchema={validationSchema}
            // validate={values => {
            //   const errors: Record<string, string> = {}
            //   console.log(values.firstName)
            //   if (!values.firstName || values.firstName === '') {
            //     errors.firstName = 'Required'
            //   }
            //   if (!values.lastName || values.lastName === '') {
            //     errors.lastName = 'Required'
            //   }
            //   if (!values.email || values.email === '') {
            //     errors.email = 'Required'
            //   }
            //   if (!values.company || values.company === '') {
            //     errors.company = 'Required'
            //   }
            //   if (!values.country || values.country === '') {
            //     errors.country = 'Required'
            //   }
            //   if (!values.researchArea || values.researchArea === '') {
            //     errors.researchArea = 'Required'
            //   }
            //   if (!values.readoutPlatform || values.readoutPlatform === '') {
            //     errors.readoutPlatform = 'Required'
            //   }
            //   return errors
            // }}
          >
            {formikProps => {
              const { isSubmitting, values, errors } = formikProps
              return (
                <Form
                  sx={{
                    input: {
                      '&.error': {
                        borderColor: '#FF0000',
                      },
                    },
                  }}
                >
                  <motion.div animate={{ opacity: isSubmitting ? 0.5 : 1 }}>
                    <Flex
                      sx={{
                        flexDirection: isLandscape ? 'row' : 'column',
                        width: '100%',
                        p: '1rem',
                        justifyContent: 'space-between',
                        flexWrap: 'wrap',
                      }}
                    >
                      <Flex
                        sx={{
                          flexBasis: '100%',
                          flexDirection: 'column',
                        }}
                      >
                        <div
                          sx={{
                            fontSize: '.5rem',
                            color: 'primary',
                            mb: '-.2rem',
                            ml: '.8rem',
                          }}
                        >
                          All Fields Required
                        </div>
                        <FormPage1 {...formikProps} />
                        {!isSubForm && <FormPage2 {...formikProps} />}
                      </Flex>
                      <Flex
                        sx={{
                          width: '100%',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <Flex sx={{ p: '2rem' }}>
                          {!isSubForm && (
                            <Button variant="primary" type="submit">
                              Schedule Consultation
                            </Button>
                          )}
                          {isSubForm && (
                            <Button
                              variant="standard"
                              onClick={() => setPage2IsOpen(true)}
                            >
                              Schedule Consultation
                            </Button>
                          )}
                        </Flex>
                      </Flex>
                    </Flex>
                  </motion.div>
                  <AnimatePresence>
                    {isSubForm && page2IsOpen === true && (
                      <motion.div
                        {...fadeAnimations}
                        sx={{
                          backgroundColor: '#fff',
                          borderRadius: '.5rem',
                          width: '100%',
                          height: '100%',
                          position: 'absolute',
                          borderWidth: '.15rem',
                          borderStyle: 'solid',
                          borderColor: 'muted',
                          opacity: 0.8,
                          top: '50%',
                          left: '50%',
                          transform: 'translate(-50%, -50%)',
                          textAlign: 'center',
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignContent: 'center',
                          alignItems: 'center',
                          p: '1rem',
                        }}
                      >
                        <CloseBtn
                          sx={{
                            position: 'absolute',
                            top: '.5rem',
                            right: '.5rem',
                          }}
                          onClick={() => setPage2IsOpen(false)}
                        />
                        <FormPage2
                          {...formikProps}
                          isSubPage
                          sx={{ fontSize: '.8rem' }}
                        />
                        <Button
                          variant="primary"
                          sx={{ py: '1rem', m: '1rem' }}
                          type="submit"
                        >
                          Schedule Consultation
                        </Button>
                      </motion.div>
                    )}
                  </AnimatePresence>
                </Form>
              )
            }}
          </Formik>
        </motion.div>
      </AnimatePresence>
      <AnimatePresence>
        {formSubmitted === true && (
          <motion.div
            {...fadeAnimations}
            sx={{
              backgroundColor: '#fff',
              borderRadius: '2rem',
              width: '20rem',
              height: '20rem',
              position: 'absolute',
              borderWidth: '.15rem',
              borderStyle: 'solid',
              borderColor: 'muted',
              opacity: 0.8,
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              textAlign: 'center',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignContent: 'center',
              alignItems: 'center',
              p: '3rem',
            }}
          >
            <p sx={{ py: '2rem' }}>
              Thank you for your request. Someone will be in touch shortly to
              schedule a live project consultation with you.
            </p>
            <Button
              variant="standard"
              onClick={() => setFormSubmitted(false)}
              sx={{}}
            >
              Continue
            </Button>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  )
}

export default ScheduleConsultForm
