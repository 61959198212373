/** @jsx jsx */
import { jsx } from 'theme-ui'
import React from 'react'
import { motion } from 'framer-motion'
import { Context } from 'components/Layout'
interface Props {
  children?: React.ReactNode
  className?: string
}

const SubpageBg: React.FC<Props> = ({
  children,
  className,
}): React.ReactElement => {
  return (
    <Context.Consumer>
      {context => (
        <React.Fragment>
          <motion.div
            className={className}
            sx={{
              padding: context.isLandscape ? '2% 6% 2% 6%' : '5%',
              width: '100%',
              height: '100%',
              zIndex: -10,
              position: 'absolute',
            }}
          >
            <div
              sx={{
                backgroundColor: '#fff',
                width: '100%',
                height: '100%',
                borderRadius: '10px',
              }}
            />
          </motion.div>
          <div
            sx={{
              padding: context.isLandscape ? '2% 6% 2% 6%' : '5%',
              position: 'absolute',
              top: 0,
              width: '100%',
              height: '100%',
            }}
          >
            <div
              sx={{
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                height: '100%',
              }}
            >
              {children}
            </div>
          </div>
        </React.Fragment>
      )}
    </Context.Consumer>
  )
}

export default SubpageBg
