/** @jsx jsx */
import { jsx, Flex, Label, Input, Radio, Select } from 'theme-ui'
import React, { useState, SyntheticEvent } from 'react'
import { motion } from 'framer-motion'
import { Field, FormikProps, useField } from 'formik'
import { formatDate } from 'utils/time'
import { FormProps } from '../ScheduleConsultForm/ScheduleConsultForm'
import * as Yup from 'yup'

interface Props {
  children?: React.ReactNode
  className?: string
  isSubPage?: boolean
}

const primaryDates: Date[] = [new Date('05/21/2020'), new Date('05/22/2020')]
const altDates: Date[] = [
  new Date('05/26/2020'),
  new Date('05/27/2020'),
  new Date('05/28/2020'),
  new Date('05/29/2020'),
]
const cutoffDate: Date = new Date('05/27/2020')

const initToday: Date = new Date()
const testDates: Date[] = [
  new Date('05/19/2020'),
  new Date('05/20/2020'),
  new Date('05/21/2020'),
  new Date('05/22/2020'),
  new Date('05/23/2020'),
  new Date('05/24/2020'),
  new Date('05/25/2020'),
  new Date('05/26/2020'),
  new Date('05/27/2020'),
  new Date('06/10/2020'),
]

export const validationSchema = Yup.object({
  researchArea: Yup.string().required(),
  readoutPlatform: Yup.string().required(),
  // preferredDay: Yup.number().required(),
  // secondaryDay: Yup.number().required(),
})

const getAltDates = (today: Date, preferredDay?: number): Date[] => {
  if (today.getFullYear() > 2020 || today.getMonth() > 4) return []
  if (today.getDate() === cutoffDate.getDate()) return []
  return altDates
    .map(date => {
      // if (preferredDay && preferredDay > date.getDate() - 2) return null
      if (date.getDate() >= today.getDate() + 2) return date
    })
    .filter(element => element != null)
}

const getPrimaryDates = (today: Date): Date[] => {
  if (today.getFullYear() > 2020 || today.getMonth() > 4) return []
  if (
    today.getFullYear() === 2020 &&
    today.getMonth() === 4 &&
    today.getDate() >= 21
  )
    return getAltDates(today)
  return primaryDates
    .map(date => {
      if (date.getDate() >= today.getDate() + 2) return date
    })
    .filter(element => element != null)
}

const getLastPrimaryDate = (): Date => primaryDates[primaryDates.length - 1]

const RadioGroup = (
  options: { id: string; label: 'string' }[],
  name: string,
  ...props
) => {
  const [groupValue, setGroupValue] = useState(null)
  const [field, meta, helpers] = useField(props)
}

const RadioTextInput = ({
  label,
  name,
  value,
  groupValue,
}: {
  label: string
  name: string
  value: string
  groupValue: string
}) => {
  // const [value, setValue] = useState('other')
  const [checked, setChecked] = useState(false)
  return (
    <Label>
      <Field
        name={name}
        type="radio"
        value={value}
        // checked={checked}
        as={Radio}
        sx={{ mx: '.5rem' }}
        // onChange={e => {
        //   console.log('value', e.currentTarget)
        //   // setChecked(!checked)
        // }}
      />
      {label}
      <span sx={{ pl: '.8rem' }}>
        {/* <Input
          // name="readoutPlatformOther"
          // htmlFor="readoutPlatform"
          placeholder="Other Platform"
          type="text"
          sx={{ mx: '.5rem' }}
          value={value}
          onChange={({
            target: { value },
          }: {
            target: EventTarget & { value: string }
          }) => {
            setValue(value)
          }}
        /> */}
      </span>
    </Label>
  )
}

const FormPage2: React.FC<Props & FormikProps<FormProps>> = ({
  children,
  className,
  isSubPage,
  ...formikProps
}): React.ReactElement => {
  const { values, errors, touched } = formikProps
  const [today, setToday] = useState(initToday)
  const [platformValue, setPlatformValue] = useState('')

  return (
    <motion.div
      className={className}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      <Flex
        sx={{
          // pt: '1rem',
          flexDirection: isSubPage ? 'column' : 'row',
          alignItems: 'flex-start',
          // justifyItems: 'flex-start',
          justifyContent: 'flex-start',
          flexBasis: '100%',
          textAlign: 'left',
          // px: '1rem',
        }}
        className={errors.firstName && touched.firstName ? 'error' : ''}
      >
        <Flex
          sx={{
            flexDirection: 'column',
            pb: '1rem',
            flexBasis: '50%',
          }}
        >
          <div
            sx={{
              fontWeight: 500,
              pb: '.3rem',
              flexBasis: '50%',
              color:
                errors.researchArea && touched.researchArea
                  ? '#ff0000'
                  : 'inherit',
            }}
          >
            1. Please describe your area of research:
          </div>
          <Label>
            <Field
              name="researchArea"
              type="radio"
              value="immunology"
              as={Radio}
              sx={{ mx: '.5rem' }}
            />
            Immunology
          </Label>
          <Label>
            <Field
              name="researchArea"
              type="radio"
              value="oncology"
              as={Radio}
              sx={{ mx: '.5rem' }}
            />
            Oncology
          </Label>
          <Label>
            <Field
              name="researchArea"
              type="radio"
              value="Neuroscience"
              as={Radio}
              sx={{ mx: '.5rem' }}
            />
            Neuroscience
          </Label>
          <Label>
            <Field
              name="researchArea"
              type="radio"
              value="infectious-disease"
              as={Radio}
              sx={{ mx: '.5rem' }}
            />
            Infectious Disease
          </Label>
        </Flex>
        <Flex sx={{ flexDirection: 'column', pb: '1rem' }}>
          <div
            sx={{
              fontWeight: 500,
              pb: '.3rem',
              color:
                errors.readoutPlatform && touched.readoutPlatform
                  ? '#ff0000'
                  : 'inherit',
            }}
          >
            2. Available platform for GeoMx Readout:
          </div>
          <Label>
            <Field
              name="readoutPlatform"
              type="radio"
              value="nCounter"
              as={Radio}
              sx={{ mx: '.5rem' }}
            />
            nCounter
          </Label>
          <Label>
            <Field
              name="readoutPlatform"
              type="radio"
              value="ngs"
              as={Radio}
              sx={{ mx: '.5rem' }}
            />
            Next Generation Sequencer (NGS)
          </Label>
          <Label>
            <Field
              name="readoutPlatform"
              type="radio"
              value="other"
              as={Radio}
              sx={{ mx: '.5rem' }}
              // onChange={e => console.log(e.currentTarget.checked)}
            />
            <span
              sx={{ mx: values.readoutPlatform === 'other' ? '.7rem' : '0rem' }}
            >
              Other
            </span>
            {values.readoutPlatform === 'other' && (
              <Input name="readoutPlatformTextfield" sx={{ ml: '0rem' }} />
            )}
          </Label>
        </Flex>
      </Flex>
      <Flex
        sx={{
          flexDirection: isSubPage ? 'column' : 'row',
          width: '100%',
          // px: isSubPage ? '1rem' : '',
          //pr: '1rem',
          justifyContent: 'space-between',
          //justifyItems: 'start',
          textAlign: 'left',
        }}
      >
        {getPrimaryDates(today).length > 0 && (
          <Flex
            sx={{
              flexDirection: 'column',
              // maxWidth: '50%',
              width: '100%',
              //pt: '1rem',
              pr: isSubPage ? '' : '1rem',
              pb: '1rem',
            }}
          >
            <div
              sx={{
                fontWeight: 500,
                pb: '.3rem',
                color:
                  errors.preferredDay && touched.preferredDay
                    ? '#ff0000'
                    : 'inherit',
              }}
            >
              3. Preferred Consultation Day:
            </div>

            <Field name="preferredDay" as={Select}>
              <option value=""> -- select an option -- </option>
              {getPrimaryDates(today).map((date: Date, i: number) => (
                <option
                  key={`dropdown-${date.getDate()}`}
                  value={date.getDate()}
                >
                  {formatDate(date)}
                </option>
              ))}
            </Field>
          </Flex>
        )}

        {getAltDates(today, parseInt(values.preferredDay)).length > 0 && (
          <Flex
            sx={{
              flexDirection: 'column',
              // maxWidth: '50%',
              width: '100%',
              //pt: '1rem',
              //pr: '1rem',
            }}
          >
            <div
              sx={{
                fontWeight: 500,
                pb: '.3rem',
                color:
                  errors.secondaryDay && touched.secondaryDay
                    ? '#ff0000'
                    : 'inherit',
              }}
            >
              Alternate Date:
            </div>
            <Field name="secondaryDay" as={Select}>
              <option value="Optional"> -- select an option -- </option>
              {getAltDates(today, parseInt(values.preferredDay)).map(
                (date: Date) => {
                  return (
                    <option
                      key={`secondary-dropdown-${date.getDate()}`}
                      value={date.getDate()}
                    >
                      {formatDate(date)}
                    </option>
                  )
                }
              )}
            </Field>
          </Flex>
        )}
      </Flex>
      {/* <Label sx={{ maxWidth: '20rem' }}>
        Test Date
        <Field
          name="testDate"
          as={Select}
          onChange={({
            target,
          }: {
            target: SyntheticEvent & { value: string }
          }) => {
            const { value } = target
            setToday(new Date(value))
          }}
          sx={{ width: '100%' }}
        >
          <option
            key={`test-${initToday.getDate()}`}
            value={initToday.toLocaleString()}
          >
            {formatDate(initToday)}
          </option>
          {testDates.map((date: Date) => (
            <option
              key={`test-${date.getDate()}`}
              value={date.toLocaleString()}
            >
              {formatDate(date)}
            </option>
          ))}
        </Field>
      </Label> */}
    </motion.div>
  )
}

export default FormPage2
