/** @jsx jsx */
import { jsx, Flex, Input } from 'theme-ui'
import React from 'react'
import { motion } from 'framer-motion'
import { Field, FormikProps, FieldAttributes } from 'formik'
import { FormProps } from '../ScheduleConsultForm/ScheduleConsultForm'
import * as Yup from 'yup'

interface Props {
  children?: React.ReactNode
  className?: string
}

export const validationSchema = Yup.object({
  firstName: Yup.string().required(),
  lastName: Yup.string().required(),
  email: Yup.string()
    .email()
    .required(),
  company: Yup.string().required(),
  country: Yup.string().required(),
})

const InputField: React.FC<FieldAttributes<{}>> = props => {
  const [field, meta] = useField<{}>(props)
  const errorText = meta.error && meta.touched ? meta.error : ''
  return <Input {...field} helperText={errorText} />
}

const FormPage1: React.FC<Props & FormikProps<FormProps>> = ({
  children,
  className,
  ...formikProps
}): React.ReactElement => {
  const { values, errors, touched } = formikProps
  return (
    <motion.div className={className}>
      <Flex sx={{ py: '.5rem' }}>
        <Field
          placeholder="First Name"
          name="firstName"
          type="input"
          as={Input}
          sx={{ mr: '.5rem' }}
          className={errors.firstName && touched.firstName ? 'error' : ''}
        />
        <Field
          placeholder="Last Name"
          name="lastName"
          type="input"
          as={Input}
          sx={{ ml: '.5rem' }}
          className={errors.lastName && touched.lastName ? 'error' : ''}
        />
      </Flex>
      <Flex sx={{ py: '.5rem' }}>
        <Field
          placeholder="Email"
          name="email"
          type="input"
          as={Input}
          sx={{ mr: '.5rem' }}
          className={errors.email && touched.email ? 'error' : ''}
        />
        <Field
          placeholder="Company/Organization"
          name="company"
          type="input"
          as={Input}
          sx={{ ml: '.5rem' }}
          className={errors.company && touched.company ? 'error' : ''}
        />
      </Flex>
      <Flex sx={{ py: '.5rem', maxWidth: '50%' }}>
        <Field
          placeholder="Country"
          name="country"
          type="input"
          as={Input}
          sx={{}}
          className={errors.country && touched.country ? 'error' : ''}
        />
      </Flex>
    </motion.div>
  )
}

export default FormPage1
